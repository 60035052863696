<template>
    <div class="roleTab roleTab-lh">
      <el-form ref="secondList" :model="secondList" label-width="120px">
        <el-form-item label="选择询价单位">
          <el-checkbox-group v-model="secondList.checkBoxList">
            <div class="listrow" :key="index" v-for="(item,index) in secondList.checkSupplier">
              <el-checkbox :label="item.label"></el-checkbox>
              <el-input v-if="secondList.checkBoxList.indexOf(item.label)>=0" v-model="item.bz" placeholder="请填写备注"></el-input>
            </div>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
export default {
  components: Component.components,
  name: 'SecondInquiry',
  props: ['listId'],
  data () {
    return {
      secondList: {
        checkBoxList: [],
        checkSupplier: []
      }
    }
  },
  created () {
    this.getSubblier()
  },
  mounted () {

  },
  methods: {
    // 根据ID请求数据
    getSubblier () {
      const id = this.listId
      if (id) {
        request('/api/inquiry/getById/' + id, 'get').then((res) => {
          if (res.code === '200') {
            res.data.inquirySupplierList.forEach(item => {
              this.secondList.checkSupplier.push({ label: item.name, value: item.code, bz: '' })
            })
          }
        })
      }
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
  .roleTab{
    .el-form-item{
      margin-bottom: 0;
    }
    .listrow{
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
      // .el-checkbox{
      //   margin-bottom: 10px;
      // }
    }
    :deep(.el-form-item__content){
      line-height: normal;
    }
    :deep(.el-form-item__label){
      line-height: normal;
    }
  }
  .roleTab-lh{
    :deep(.el-form-item__label){
      margin-top: 10px;
    }
    :deep(.el-input){
      margin-left: 10px;
    }
  }
</style>
